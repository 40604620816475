<template>
  <nav class="navbar navbar-dark fixed-top bg-dark flex-md-nowrap p-0 shadow">
    <a class="navbar-brand col-sm-3 col-md-2 mr-0" href="#">PoolFit.com</a>
    <!-- <input class="form-control form-control-dark w-100" type="text" placeholder="Search" aria-label="Search" data-com.agilebits.onepassword.user-edited="yes"> -->
    <ul class="navbar-nav px-3">
      <li class="nav-item text-nowrap">
        <a class="nav-link" href="#" @click="logout">Sign out</a>
      </li>
    </ul>
  </nav>
</template>

<script>
export default {
  data() {
    return {
      isLoggedIn: false
    };
  },

  methods: {
    logout(event) {
      event.preventDefault();
      this.$cookies.remove("poolfit_admin_session");
      this.isLoggedIn = false;

      window.location.href = "/login";
    }
  },

  created() {
    let token = this.$cookies.get("poolfit_admin_session");

    this.isLoggedIn = token && token.length > 0 ? true : false;

    if (this.isLoggedIn === true) {
      let options = {
        headers: { "X-Access-Token": token }
      };

      this.$http
        .get(process.env.VUE_APP_ROOT_API + "/customers/profile", options)
        .then(
          function(response) {
            console.log(response);
          },
          function(err) {
            if (this.$router.currentRoute.path !== "/login") {
              window.location.href = "/login";
            }
          }
        );
    } else {
      if (this.$router.currentRoute.path !== "/login") {
        window.location.href = "/login";
      }
    }
  }
};
</script>
