<template>
  <div class="row justify-content-center">
    <div class="col-lg-6 col-md-6 col-sm-12">
      <div class="card">
        <h5 class="card-header">Sign in to PoolFit</h5>
        <div class="card-body">
          <form v-on:submit.prevent="login">
            <div class="alert alert-danger" v-if="valid == false" role="alert">
              Please check your login credentials and try again!
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="email" class="form-label">Email:</label>
                <input
                  class="form-control form-control-lg"
                  type="text"
                  placeholder="email"
                  id="email"
                  v-model="email"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <label for="password" class="form-label">Password:</label>
                <input
                  class="form-control form-control-lg"
                  type="password"
                  placeholder="password"
                  id="password"
                  v-model="password"
                />
              </div>
            </div>
            <div class="form-group row">
              <div class="col-12">
                <button class="btn btn-primary float-right">Login</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    login() {
      let $this = this;
      let userDataObj = {
        email: this.email,
        password: this.password
      };

      $this.isWaiting = true;
      this.$http
        .post(process.env.VUE_APP_ROOT_API + "/auth/login", userDataObj)
        .then(response => {
          if (response.data.customer_profile.permission === "admin") {
            this.$cookies.set("poolfit_admin_session", response.data.token);

            $this.isWaiting = false;

            window.location.href = "/";
          }
        })
        .catch(err => {
          $this.isWaiting = false;
          this.valid = false;
          this.$cookies.remove("poolfit_admin_session");
          console.log(err);
        });
    }
  },

  data: function() {
    // use this method to get data.
    return {
      isWaiting: false,
      email: "",
      password: "",
      valid: true
    };
  }
};
</script>
<style></style>
