import jquery from "jquery/dist/jquery.js";
window.$ = window.jQuery = jquery;

import "bootstrap";
import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import VueAxios from "vue-axios";
import axios from "axios";
import VueCookies from "vue-cookies";
import VeeValidate from "vee-validate/dist/vee-validate.js";
import VueMoment from "vue-moment/dist/vue-moment.js";

Vue.use(VueAxios, axios);
Vue.use(VueCookies);
Vue.use(VeeValidate);
Vue.use(VueMoment);

Vue.$cookies.config("365d", "/");

Vue.config.productionTip = false;

Vue.filter('dateformat', function (value) {
  if (!value) return ''

  const date = new Date(value);
  return date.toLocaleDateString("en-IN", {
    "year": "numeric",
    "month": "long",
    "day": "numeric",
    "timeZone": 'UTC'
    });
});

router.beforeEach((to, from, next) => {
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
  let token = Vue.$cookies.get("poolfit_admin_session");
  let isLoggedIn = token && token.length > 0 ? true : false;

  if (requiresAuth && !isLoggedIn) {
    next("/login");
  } else if (requiresAuth && isLoggedIn) {
    next();
  } else {
    next();
  }
});

new Vue({
  router,
  render: h => h(App)
}).$mount("#app");
