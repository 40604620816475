import Vue from "vue";
import Router from "vue-router";
import MainLayout from "./components/MainLayout.vue";
import Login from "./views/Login.vue";

Vue.use(Router);

export default new Router({
  mode: "history",
  base: process.env.BASE_URL,
  routes: [
    {
      path: "/",
      name: "admin-layout",
      component: MainLayout,
      children: [
        {
          path: "/",
          name: "dashboard",
          component: () =>
            import(/* webpackChunkName: "dashboard" */ "./views/Dashboard.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos",
          name: "video-list",
          component: () =>
            import(
              /* webpackChunkName: "video-list" */ "./views/VideoList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/categories",
          name: "category-videos-list",
          component: () =>
            import(
              /* webpackChunkName: "category-video-list" */ "./views/VideoCategoriesList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/categories/:id",
          name: "category-videos-record",
          component: () =>
            import(
              /* webpackChunkName: "category-video-record" */ "./views/VideoCategoriesForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/categories/add",
          name: "category-videos-add",
          component: () =>
            import(
              /* webpackChunkName: "category-video-add" */ "./views/VideoCategoriesForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/categorize",
          name: "categorize-videos-list",
          component: () =>
            import(
              /* webpackChunkName: "categorize-video-list" */ "./views/VideoAssignCategoryList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/categorize/:id",
          name: "categorize-videos-record",
          component: () =>
            import(
              /* webpackChunkName: "categorize-video-record" */ "./views/VideoAssignCategoryForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/categorize/add",
          name: "categorize-videos-add",
          component: () =>
            import(
              /* webpackChunkName: "categorize-video-add" */ "./views/VideoAssignCategoryForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/:id",
          name: "video-record",
          component: () =>
            import(
              /* webpackChunkName: "video-record" */ "./views/VideoForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/videos/add",
          name: "video-add",
          component: () =>
            import(/* webpackChunkName: "video-add" */ "./views/VideoForm.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/tips",
          name: "tip-list",
          component: () =>
            import(/* webpackChunkName: "tip-list" */ "./views/TipList.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/tips/:id",
          name: "tip-record",
          component: () =>
            import(/* webpackChunkName: "tip-record" */ "./views/TipForm.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/tips/add",
          name: "tip-add",
          component: () =>
            import(/* webpackChunkName: "tip-add" */ "./views/TipForm.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/banners",
          name: "banner-list",
          component: () =>
            import(/* webpackChunkName: "blog-list" */ "./views/BannerList.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/banners/:id",
          name: "blog-record",
          component: () =>
            import(
              /* webpackChunkName: "blog-record" */ "./views/BannerForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/banners/add",
          name: "blog-add",
          component: () =>
            import(/* webpackChunkName: "blog-add" */ "./views/BannerForm.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/testimonials",
          name: "testimonials",
          component: () => import("./views/TestimonialList.vue"),
        },

        {
          path: "/testimonials/:id",
          name: "testimonials-update",
          component: () => import("./views/TestimonialForm.vue"),
        },

        {
          path: "/testimonials/add",
          name: "testimonials-add",
          component: () => import("./views/TestimonialForm.vue"),
        },
        {
          path: "/blogs",
          name: "blog-list",
          component: () =>
            import(/* webpackChunkName: "blog-list" */ "./views/BlogList.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/blogs/:id",
          name: "blog-record",
          component: () =>
            import(
              /* webpackChunkName: "blog-record" */ "./views/BlogForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/blogs/add",
          name: "blog-add",
          component: () =>
            import(/* webpackChunkName: "blog-add" */ "./views/BlogForm.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/media",
          name: "media-list",
          component: () =>
            import(/* webpackChunkName: "media-list" */ "./views/MediaList.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/media/:id",
          name: "media-record",
          component: () =>
            import(
              /* webpackChunkName: "media-record" */ "./views/MediaForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/media/add",
          name: "media-add",
          component: () =>
            import(/* webpackChunkName: "media-add" */ "./views/MediaForm.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/audios",
          name: "audio-list",
          component: () =>
            import(
              /* webpackChunkName: "audio-list" */ "./views/AudioList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/audios/:id",
          name: "audio-record",
          component: () =>
            import(
              /* webpackChunkName: "audio-record" */ "./views/AudioForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/audios/add",
          name: "audio-add",
          component: () =>
            import(/* webpackChunkName: "audio-add" */ "./views/AudioForm.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/instructors",
          name: "audio-list",
          component: () =>
            import(
              /* webpackChunkName: "instructor-list" */ "./views/InstructorList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/instructors/:id",
          name: "instructor-record",
          component: () =>
            import(
              /* webpackChunkName: "instructor-record" */ "./views/InstructorForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/instructors/add",
          name: "instructor-add",
          component: () =>
            import(
              /* webpackChunkName: "instructor-add" */ "./views/InstructorForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/users",
          name: "user-list",
          component: () =>
            import(/* webpackChunkName: "user-list" */ "./views/UserList.vue"),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/users/profile/:id",
          name: "user-record",
          component: () =>
            import(
              /* webpackChunkName: "user-record" */ "./views/UserProfileForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/users/password/:id",
          name: "user-password",
          component: () =>
            import(
              /* webpackChunkName: "user-password" */ "./views/UserPasswordForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/users/filter/:filter",
          name: "user-list-filtered",
          component: () =>
            import(
              /* webpackChunkName: "user-list-filtered" */ "./views/UserList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace",
          name: "marketplace-list",
          component: () =>
            import(
              /* webpackChunkName: "marketplace-list" */ "./views/MarketplaceList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/categories",
          name: "category-marketplace-list",
          component: () =>
            import(
              /* webpackChunkName: "category-marketplace-list" */ "./views/MarketplaceCategoriesList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/categories/:id",
          name: "category-marketplace-record",
          component: () =>
            import(
              /* webpackChunkName: "category-marketplace-record" */ "./views/MarketplaceCategoriesForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/categories/add",
          name: "category-marketplace-add",
          component: () =>
            import(
              /* webpackChunkName: "category-marketplace-add" */ "./views/MarketplaceCategoriesForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/categorize",
          name: "categorize-marketplace-list",
          component: () =>
            import(
              /* webpackChunkName: "categorize-marketplace-list" */ "./views/MarketplaceAssignCategoryList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/categorize/:id",
          name: "categorize-marketplace-record",
          component: () =>
            import(
              /* webpackChunkName: "categorize-marketplace-record" */ "./views/MarketplaceAssignCategoryForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/categorize/add",
          name: "categorize-marketplace-add",
          component: () =>
            import(
              /* webpackChunkName: "categorize-marketplace-add" */ "./views/MarketplaceAssignCategoryForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/related",
          name: "related-marketplace-list",
          component: () =>
            import(
              /* webpackChunkName: "related-marketplace-list" */ "./views/MarketplaceAssignRelatedVideoList.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/related/add",
          name: "related-marketplace-add",
          component: () =>
            import(
              /* webpackChunkName: "related-marketplace-add" */ "./views/MarketplaceAssignRelatedVideoForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/:id",
          name: "marketplace-record",
          component: () =>
            import(
              /* webpackChunkName: "marketplace-record" */ "./views/MarketplaceForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/marketplace/add",
          name: "marketplace-add",
          component: () =>
            import(
              /* webpackChunkName: "marketplace-add" */ "./views/MarketplaceForm.vue"
            ),
          meta: {
            requiresAuth: true
          }
        },
        {
          path: "/cloudfront-cdn-link-generator",
          name: "cloudfront-link-generator",
          component: () => import("./views/cloudfrontLinkGen.vue"),
          meta: {
            requiresAuth: true
          }
        }
      ]
    },
    {
      path: "/login",
      name: "login",
      component: Login
    }
  ]
});
