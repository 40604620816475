<template>
  <div id="layout">
    <pagehead></pagehead>
    <div class="container-fluid">
      <div class="row">
        <pagesidebar></pagesidebar>
        <main role="main" class="col-md-9 ml-sm-auto col-lg-10 px-4">
          <router-view></router-view>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import PageHead from "./PageHead.vue";
import PageSidebar from "./PageSidebar.vue";

export default {
  components: {
    pagehead: PageHead,
    pagesidebar: PageSidebar
  }
};
</script>
